<div class="container h-100">
    <div class="row">
        <div class="col-12">
            <h1 class="page-title">{{'general.pagetitle' | translate}}</h1>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-sm-6 d-flex justify-content-center">
            <div class="square-tile">
                <div class="iconbox"><img src="./assets/img/ear.svg"></div>
                <div class="textbox">{{'introduction.ear' | translate}}</div>
            </div>
        </div>
        <div class="col-12 col-sm-6 d-flex justify-content-center">
            <div class="square-tile">
                <div class="iconbox"><img class="icon_img" src="./assets/img/lung.svg"></div>
                <div class="textbox">{{'introduction.lung' | translate}}</div>
            </div>
        </div>
        <div class="col-12 col-sm-6 d-flex justify-content-center">
            <div class="square-tile">
                <div class="iconbox"><img class="icon_img" src="./assets/img/checkboard.svg"></div>
                <div class="textbox">{{'introduction.checkboard' | translate}}</div>
            </div>
        </div>
        <div class="col-12 col-sm-6 d-flex justify-content-center">
            <div class="square-tile">
                <div class="iconbox"><img class="icon_img" src="./assets/img/lamp.svg"></div>
                <div class="textbox">{{'introduction.lamp' | translate}}</div>
            </div>
        </div>
        <div class="col-12 d-flex justify-content-center">
            <div class="square-tile">
                <div class="iconbox"><img class="icon_img" src="./assets/img/repeat.svg"></div>
                <div class="textbox">{{'introduction.arrows' | translate}}</div>
            </div>
        </div>
    </div>
    <div class="row pb-3">
        <div class="col-12 d-flex justify-content-center">
            <div class="big-button" (click)="startQuiz()">{{'buttons.go' | translate}}</div>
        </div>
    </div>
</div>