<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <h1 class="page-title">{{'general.pagetitle' | translate}}</h1>
            <p class="quiz-subtitle">
                {{'quiz.subtitle' | translate:{currentQuestion: (activeQuestionIndex+1), questions: totalQuestions} }}
            </p>
        </div>
    </div>
    <div class="row">
        <div class="col-12 d-flex justify-content-center">
            <div class="audio-container w-100 d-flex justify-content-center">
                <img src="./assets/img/audio.svg" class="mw-100"/>
                <div class="play-btn-container w-100 d-flex align-items-center justify-content-center">
                    <img class="play-button" src="./assets/img/button_play.svg" (click)="playAudio()" *ngIf="!audioIsPlaying"/>
                    <img class="play-button" src="./assets/img/button_pause.svg" (click)="pauseAudio()" *ngIf="audioIsPlaying"/>
                </div>                
            </div>
        </div>
    </div>
    
    <div class="row">
        <div class="col-12 d-flex justify-content-center">
            <p *ngIf="!answerSelected" class="quiz-question">{{'quiz.question' | translate}}</p>
            <p *ngIf="answerSelected" class="quiz-question">{{'quiz.play_again' | translate}}</p>
        </div>
    </div>
    
    <div class="row">
        <div class="col-12 col-sm-6 d-flex justify-content-center">
            <div class="quiz-tile" (click)="selectAnswer('copd')" [class.wrongAnswer]="answerSelected && selectedAnswer == 'copd' && correctAnswer !== 'copd'" [class.notSelected]="answerSelected && selectedAnswer !== 'copd'">
                <span>{{'quiz.copd' | translate}}</span>
                <div class="marker"><img src="./assets/img/correct.svg" *ngIf="answerSelected && correctAnswer === 'copd'" /></div>
                <div class="marker"><img src="./assets/img/wrong.svg" *ngIf="answerSelected && correctAnswer !== 'copd'" /></div>
            </div>
        </div>
    
        <div class="col-12 col-sm-6 d-flex justify-content-center">
            <div class="quiz-tile" (click)="selectAnswer('ipf')" [class.wrongAnswer]="answerSelected && selectedAnswer == 'ipf' && correctAnswer !== 'ipf'" [class.notSelected]="answerSelected && selectedAnswer !== 'ipf'">
                <span>{{'quiz.ipf' | translate}}</span>
                <div class="marker"><img src="./assets/img/correct.svg" *ngIf="answerSelected && correctAnswer === 'ipf'" /></div>
                <div class="marker"><img src="./assets/img/wrong.svg" *ngIf="answerSelected && correctAnswer !== 'ipf'" /></div>
            </div>
        </div>
    
        <div class="col-12 col-sm-6 d-flex justify-content-center">
            <div class="quiz-tile" (click)="selectAnswer('other')" [class.wrongAnswer]="answerSelected && selectedAnswer == 'other' && correctAnswer !== 'other'" [class.notSelected]="answerSelected && selectedAnswer !== 'other'">
                <span>{{'quiz.other' | translate}}</span>
                <div class="marker"><img src="./assets/img/correct.svg" *ngIf="answerSelected && correctAnswer === 'other'" /></div>
                <div class="marker"><img src="./assets/img/wrong.svg" *ngIf="answerSelected && correctAnswer !== 'other'" /></div>
            </div>
        </div>
    
        <div class="col-12 col-sm-6 d-flex justify-content-center">
            <div class="quiz-tile" (click)="selectAnswer('healthy')" [class.wrongAnswer]="answerSelected && selectedAnswer == 'healthy' && correctAnswer !== 'healthy'" [class.notSelected]="answerSelected && selectedAnswer !== 'healthy'">
                <span>{{'quiz.healthy' | translate}}</span>
                <div class="marker"><img src="./assets/img/correct.svg" *ngIf="answerSelected && correctAnswer === 'healthy'" /></div>
                <div class="marker"><img src="./assets/img/wrong.svg" *ngIf="answerSelected && correctAnswer !== 'healthy'" /></div>
            </div>
        </div>
    </div>
    
    <div class="row" *ngIf="!answerSelected && activeQuestionIndex > 0">
        <div class="col-12 d-flex justify-content-center">
            <div class="big-button" (click)="restartQuiz()">{{'buttons.restart' | translate}}</div>
        </div>
    </div>
    
    <div class="row" *ngIf="answerSelected">
        <div class="col-12 d-flex justify-content-between">
            <div class="small-button" (click)="restartQuiz()">{{'buttons.restart' | translate}}</div>
            <div class="small-button" (click)="nextQuestion()">{{'buttons.next' | translate}}</div>
        </div>
    </div>
</div>
