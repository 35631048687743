import { Component, ElementRef, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BaseComponent } from "../BaseComponent";

@Component({
    selector: "app-thankyou",
    templateUrl: "./thankyou.component.html",
    styleUrls: ["./thankyou.component.scss"]
})
export class ThankYouComponent extends BaseComponent implements OnInit {
    protected whiteBackground: boolean = true;

    public totalQuestions = "0";
    public correctAnswers = "0";

    constructor(private router: Router, private route: ActivatedRoute, protected elementRef: ElementRef) {
        super(elementRef);

        this.totalQuestions = this.route.snapshot.paramMap.get("totalQuestions");
        this.correctAnswers = this.route.snapshot.paramMap.get("correctAnswers");
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    restartQuiz() {
        this.router.navigate(["quiz"]);
    }
}
