import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { IntroductionComponent } from "./components/introduction/introduction.component";
import { SingleLayoutComponent } from "./components/single-layout/single-layout.component";
import { StartComponent } from "./components/start/start.component";
import { QuizComponent } from "./components/quiz/quiz.component";
import { ThankYouComponent } from "./components/thankyou/thankyou.component";

const routes: Routes = [
    {
        path: "",
        component: SingleLayoutComponent,
        children: [
            {
                path: "",
                redirectTo: "start",
                pathMatch: "full"
            },
            {
                path: "start",
                component: StartComponent
            },
            {
                path: "intro",
                component: IntroductionComponent
            },
            {
                path: "quiz",
                component: QuizComponent
            },
            {
                path: "thankyou",
                component: ThankYouComponent
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true })],
    exports: [RouterModule]
})
export class AppRoutingModule {}
