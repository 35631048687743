export interface IQuizData {
    answer: QuizAnswer;
    file: string;
}

export enum QuizAnswer {
    copd = "copd",
    healthy = "healthy",
    ipf = "ipf",
    other = "other"
}

export class QuizData {
    public static readonly QUIZDATA: IQuizData[] = [
        {
            file: "./assets/sounds/copd/COPD_01.mp3",
            answer: QuizAnswer.copd
        },
        {
            file: "./assets/sounds/copd/COPD_02.mp3",
            answer: QuizAnswer.copd
        },
        {
            file: "./assets/sounds/healthy/Healthy_01.mp3",
            answer: QuizAnswer.healthy
        },
        {
            file: "./assets/sounds/healthy/Healthy_02.mp3",
            answer: QuizAnswer.healthy
        },
        {
            file: "./assets/sounds/ipf/IPF_01.mp3",
            answer: QuizAnswer.ipf
        },
        {
            file: "./assets/sounds/ipf/IPF_02.mp3",
            answer: QuizAnswer.ipf
        },
        {
            file: "./assets/sounds/ipf/IPF_03.mp3",
            answer: QuizAnswer.ipf
        },
        {
            file: "./assets/sounds/ipf/IPF_04.mp3",
            answer: QuizAnswer.ipf
        },
        {
            file: "./assets/sounds/ipf/IPF_05.mp3",
            answer: QuizAnswer.ipf
        },
        {
            file: "./assets/sounds/ipf/IPF_06.mp3",
            answer: QuizAnswer.ipf
        },
        {
            file: "./assets/sounds/ipf/IPF_07.mp3",
            answer: QuizAnswer.ipf
        },
        {
            file: "./assets/sounds/ipf/IPF_08.mp3",
            answer: QuizAnswer.ipf
        },
        {
            file: "./assets/sounds/ipf/IPF_09.mp3",
            answer: QuizAnswer.ipf
        },
        {
            file: "./assets/sounds/ipf/IPF_10.mp3",
            answer: QuizAnswer.ipf
        },
        {
            file: "./assets/sounds/ipf/IPF_11.mp3",
            answer: QuizAnswer.ipf
        },
        {
            file: "./assets/sounds/ipf/IPF_12.mp3",
            answer: QuizAnswer.ipf
        },
        {
            file: "./assets/sounds/other/Hypersensitivity_pneumonitis_01.mp3",
            answer: QuizAnswer.other
        }
    ];
}
