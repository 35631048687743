import { BrowserModule } from "@angular/platform-browser";
import { NgModule, Injector, APP_INITIALIZER } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { TranslateModule, TranslateService, TranslateLoader } from "@ngx-translate/core";
import { translateLoaderService } from "./global/translate/translateloader.service";
import { LOCATION_INITIALIZED } from "@angular/common";
import { SingleLayoutComponent } from "./components/single-layout/single-layout.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { MatButtonModule } from "@angular/material/button";
import { MatDividerModule } from "@angular/material/divider";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { RouteReuseStrategy } from "@angular/router";
import { CustomRouteReuseStrategy } from "./global/CustomRouteReuseStrategy";
import { StartComponent } from "./components/start/start.component";
import { IntroductionComponent } from "./components/introduction/introduction.component";
import { QuizComponent } from "./components/quiz/quiz.component";
import { ThankYouComponent } from "./components/thankyou/thankyou.component";

export function appInitializerFactory(translate: TranslateService, injector: Injector) {
    return () =>
        new Promise<any>((resolve: any) => {
            const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
            locationInitialized.then(() => {
                translate.addLangs(["de"]);

                const langToSet = "de";
                translate.setDefaultLang("de");
                translate.use(langToSet).subscribe(
                    () => {
                        // language initialized
                    },
                    (err) => {
                        console.error(`Problem with '${langToSet}' language initialization.'`);
                    },
                    () => {
                        resolve(null);
                    }
                );
            });
        });
}

@NgModule({
    declarations: [AppComponent, SingleLayoutComponent, StartComponent, IntroductionComponent, QuizComponent, ThankYouComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        HttpClientModule,
        TranslateModule.forRoot(),
        BrowserAnimationsModule,
        MatIconModule,
        MatListModule,
        MatDividerModule,
        MatButtonModule,
        MatFormFieldModule,
        MatSelectModule,
        ReactiveFormsModule
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerFactory,
            deps: [TranslateService, Injector],
            multi: true
        },
        {
            provide: TranslateLoader,
            useFactory: translateLoaderService,
            deps: [HttpClient]
        },
        {
            provide: RouteReuseStrategy,
            useClass: CustomRouteReuseStrategy
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
