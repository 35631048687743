import { Component, ElementRef, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { BaseComponent } from "../BaseComponent";

@Component({
    selector: "app-introduction",
    templateUrl: "./introduction.component.html",
    styleUrls: ["./introduction.component.scss"]
})
export class IntroductionComponent extends BaseComponent implements OnInit {
    protected whiteBackground: boolean = false;

    constructor(private router: Router, protected elementRef: ElementRef) {
        super(elementRef);
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    startQuiz() {
        this.router.navigate(["quiz"]);
    }
}
