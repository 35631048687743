<div class="thankyou h-100 start-end-screen">
    <div class="container-fluid h-100 max-width-container">
        <div class="row h-100 align-items-baseline">
            <div class="col-12 title">
                <div class="row">
                    <img class="col-3" src="./assets/img/ear_ext.svg" alt="">
                    <div class="col-9 d-flex justify-content-left align-items-end mb-2 pl-2">
                        <h1 style="vertical-align: super;">{{ "general.pagetitle" | translate }}</h1>
                    </div>
                </div>
            </div>
            <div class="col-12 subtitle">
                {{ "thankyou.participation" | translate }}
            </div>
            <div class="col-12 subtitle-small">
                {{ "thankyou.status" | translate: { totalQuestions: totalQuestions, correctAnswers: correctAnswers } }}
            </div>
    
            <div class="col-3"></div>
            <div class="col-6 big-button start-btn" (click)="restartQuiz()">{{'buttons.restart' | translate}}</div>
            <div class="col-3"></div>
            <img class="col-7 col-sm-6 mb-3" src="./assets/img/logo_wissens_werte@1x.png" srcset="./assets/img/logo_wissens_werte@2x.png 2x">
            <div class="col-5 col-sm-6"></div>
        </div>
    </div>    
</div>
