import { Component, ElementRef, OnInit } from "@angular/core";
import { BaseComponent } from "../BaseComponent";
import { IQuizData, QuizData } from "../../data/quiz.data";
import { Router } from "@angular/router";

@Component({
    selector: "app-quiz",
    templateUrl: "./quiz.component.html",
    styleUrls: ["./quiz.component.scss"]
})
export class QuizComponent extends BaseComponent implements OnInit {
    public static readonly numberOfQuestions = 5;

    public quizData: IQuizData[] = [];
    public activeQuestionIndex = 0;
    public audioIsPlaying = false;
    public playedAudioOnce = false;
    public totalQuestions = QuizComponent.numberOfQuestions;

    public answerSelected = false;
    public selectedAnswer: string;
    public correctAnswer: string;

    protected whiteBackground: boolean = false;

    private audio = new Audio();
    private audioPlayCheckInterval: any;
    private correctAnswers = 0;

    constructor(private router: Router, protected elementRef: ElementRef) {
        super(elementRef);

        this.initQuiz();
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    playAudio() {
        this.audioIsPlaying = true;
        this.audio.play();
        this.audioPlayCheckInterval = setInterval(() => {
            if (this.audio.paused) {
                this.audioIsPlaying = false;
                clearInterval(this.audioPlayCheckInterval);
            }
        }, 200);
        this.playedAudioOnce = true;
    }

    pauseAudio() {
        this.audio.pause();
    }

    stopAudio() {
        this.audio.pause();
        this.audio.currentTime = 0;
    }

    selectAnswer(answer: string) {
        if (!this.answerSelected && this.playedAudioOnce) {
            this.answerSelected = true;
            this.selectedAnswer = answer;

            if (this.selectedAnswer === this.correctAnswer) {
                this.correctAnswers++;
            }
            if (this.audioIsPlaying) {
                this.stopAudio();
            }
        }
    }

    nextQuestion() {
        if (this.activeQuestionIndex === this.quizData.length - 1) {
            this.initPageState(); // stop sound
            this.router.navigate(["thankyou", { correctAnswers: this.correctAnswers, totalQuestions: this.totalQuestions }]);
            return;
        }
        this.activeQuestionIndex++;
        this.initPageState();
    }

    restartQuiz() {
        this.initQuiz();
    }

    // everything we need to do on start of a new quiz, we do here
    private initQuiz() {
        // get random questions
        this.loadRandomNonUniqueQuestions();
        this.activeQuestionIndex = 0;
        this.correctAnswers = 0;

        this.initPageState();
    }

    // get unique random questions out of pool
    private loadRandomUniqueQuestions() {
        const shuffledData = JSON.parse(JSON.stringify(QuizData.QUIZDATA.sort(() => 0.5 - Math.random())));
        this.quizData = shuffledData.slice(0, QuizComponent.numberOfQuestions);
    }

    // get non-unique random questions out of pool (same question can occur multiple times)
    private loadRandomNonUniqueQuestions() {
        const shuffledData = JSON.parse(JSON.stringify(QuizData.QUIZDATA.sort(() => 0.5 - Math.random())));
        this.quizData = [];

        for (let i = 0; i < QuizComponent.numberOfQuestions; i++) {
            this.quizData.push(shuffledData[Math.floor(Math.random() * shuffledData.length)]);
        }
    }

    // everything we need to do on a new question / question change, we do here
    private initPageState() {
        this.audio.pause();
        this.audio.src = this.quizData[this.activeQuestionIndex].file;
        this.audio.load();
        this.audioIsPlaying = false;
        this.playedAudioOnce = false;
        clearInterval(this.audioPlayCheckInterval);

        this.answerSelected = false;
        this.selectedAnswer = "";
        this.correctAnswer = this.quizData[this.activeQuestionIndex].answer;
    }
}
