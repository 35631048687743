import { Component, OnDestroy, AfterViewInit, ElementRef, OnInit } from "@angular/core";
import { Subscription } from "rxjs";

@Component({ selector: "", template: `<div></div>` })
export abstract class BaseComponent implements OnDestroy, OnInit, AfterViewInit {
    protected abstract whiteBackground: boolean;

    public subscriptions: Subscription[] = [];

    private classToAdd = "";
    private classToRemove = "";

    constructor(protected elementRef: ElementRef) {}

    ngOnInit() {
        // Set body background to white or blue
        if (this.whiteBackground) {
            this.classToAdd = "whiteBackground";
            this.classToRemove = "blueBackground";
        } else {
            this.classToAdd = "blueBackground";
            this.classToRemove = "whiteBackground";
        }
    }

    ngAfterViewInit() {
        this.elementRef.nativeElement.ownerDocument.body.classList.add(this.classToAdd);
        this.elementRef.nativeElement.ownerDocument.body.classList.remove(this.classToRemove);
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => {
            subscription.unsubscribe();
        });
    }
}
